import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/fr/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/fr/lexique/">Lexique</a>
                    </li>
                    <li className="last">
                      <a href="/fr/faq-questions-diarrhee/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li id="uilist-hor nav-top nav-lang_nl-BE">
                      <a href="/imodium-behandelen-diarree/imodium-instant/">
                        NL
                      </a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr" className="active">
                      <a href="/fr/medicaments-imodium/imodium-instant/">FR</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="b61408c721a95a5424c4fc553f69c7211e09eff9"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/fr/chercher"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <span className="logo">
                  <a href="/fr/">Imodium®</a>
                </span>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/fr/comment-agit-imodium/">
                          Comment agit
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li className="parent-active" id="nav-sub-5">
                        <a href="/fr/medicaments-imodium/">
                          Médicaments <br />
                          IMODIUM®
                        </a>
                      </li>
                      <li id="nav-sub-6">
                        <a href="/fr/la-diarrhee/">
                          Diarrhée – causes
                          <br />
                          et traitement
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/fr/diarrhee-en-voyage/">
                          Diarrhée
                          <br />
                          en voyage
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/fr/diarrhee-chez-les-enfants/">
                          Diarrhée chez
                          <br />
                          les enfants
                        </a>
                      </li>                      
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/fr/medicaments-imodium/"> Médicaments IMODIUM® </a>
              </li>
              <li className="active">
                <a href="/fr/medicaments-imodium/imodium-instant/">
                  IMODIUM® Instant
                </a>
              </li>
              <li>
                <a href="/fr/medicaments-imodium/imodium-duo/">IMODIUM® Duo</a>
              </li>
              <li>
                <a href="/fr/medicaments-imodium/imodium-capsules/">
                  IMODIUM® Capsules
                </a>
              </li>
              
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Conseils en cas de diarrhée</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_durchfallmythen.jpeg"
                    width="170"
                    height="102"
                    alt="Conseils en cas de diarrhée"
                  />
                </div>
                <p>
                  Quand il s'agit du traitement des symptômes de la diarrhée, il
                  existe encore de nombreuses incertitudes. Lire quelques
                  conseils.
                </p>
                <a href="/fr/la-diarrhee/conseils-pour-le-traitement-des-symptomes-de-la-diarrhee/">
                  Conseils diarrhée
                </a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-42">
            <h1>IMODIUM® Instant comprimés orodispersibles</h1>
            <div className="row">
              <div className="six columns">
                <img
                  alt="IMODIUM® Instant une aide immédiate en cas de diarrhée"
                  src="/assets/files/products/images/Imodium_Instant_2mg_20_tabs_3D_Frontal_FR.png"
                  style={{
                    width: "322px",
                    height: "178px"
                  }}
                />
              </div>
              <div className="six columns">
                <h4>IMODIUM® Instant: Une aide pratique en cas de diarrhée</h4>
                <ul>
                  <li>Fond sur la langue en quelques secondes</li>
                  <li>Facile à prendre sans eau</li>
                  <li>Agit rapidement et efficacement</li>
                </ul>
              </div>
            </div>
            <p>
              <a href="http://bijsluiters.fagg-afmps.be/?localeValue=fr">
                <img
                  alt
                  src="/assets/files/pages/bijlsuiter.jpeg"
                  style={{
                    width: "48px",
                    height: "47px"
                  }}
                />
                Télécharger&nbsp;
              </a>
              <a href="http://bijsluiters.fagg-afmps.be/?localeValue=fr">
                la notice
              </a>
              &nbsp;IMODIUM Instant<sup>®</sup>
            </p>
            <h4>
              Utilisation de IMODIUM<sup>®</sup> Instant
            </h4>
            <p>
              IMODIUM<sup>®</sup> Instant contient 2 mg de lopéramide. Il peut
              être utilisé pour le traitement symptomatique de la diarrhée aiguë
              (de courte durée) et chronique d’origines diverses.
            </p>
            <p>
              <strong>IMODIUM® Instant en cas de diarrhée</strong>
            </p>
            <p>
              IMODIUM® Instant, à base de lopéramide, réduit l'activité intestinale excessive de telle manière que les selles
              redeviennent solides et la diarrhée disparaît.
            </p>
            <p>
              De cette façon, la déshydratation par perte d'eau et
              d'électrolytes est limitée. Imodium instant peut aussi être
              conseillé pour la diarrhée en voyage (diarrhée du voyageur). Dans
              ce cas, un traitement de 2 jours doit suffire.
            </p>
            <p>
              IMODIUM<sup>®</sup> Instant est particulièrement pratique si vous
              avez du mal à avaler les comprimés ou si vous êtes en déplacement.
              Puisque le comprimé&nbsp; goût menthe fond dans la bouche en
              seulement quelques secondes, vous pouvez prendre IMODIUM
              <sup>®</sup> Instant sans problèmes lorsque la diarrhée est
              accompagnée de nausée.
            </p>
            <p>
              IMODIUM<sup>®</sup> instant comprimés orodispersibles existe en
              conditionnement de 20 ou 60 comprimés orodispersibles.&nbsp;Le
              médicament est disponible en pharmacie sans prescription.
            </p>
            <h4>
              IMODIUM<sup>® </sup>Instant, comprimés orodispersibles&nbsp;2 mg
            </h4>
            <p>
              Les comprimés orodispersibles IMODIUM<sup>®</sup> instant
              conviennent pour les adultes et les enfants à partir de 6 ans.
              Contient du{" "}
              <a href="/fr/comment-agit-imodium/la-substance-active-loperamide/">
                lopéramide
              </a>
              . Toujours lire la notice du produit.
            </p>
            <p>
              Les comprimés d'IMODIUM Instant fondent sans ajout d'eau sur la
              langue.
            </p>
            <h3>
              <img
                alt
                src="/assets/files/pages/werkzame-stof.jpeg"
                style={{
                  width: "26px",
                  height: "26px"
                }}
              />
              &nbsp;Substance active de IMODIUM<sup>®</sup> Instant&nbsp;:
            </h3>
            <p>
              La substance active de IMODIUM<sup>®</sup> Intant pour le
              traitement symptomatique des symptômes de la diarrhée est le
              chlorhydrate de{" "}
              <a href="/fr/comment-agit-imodium/la-substance-active-loperamide/">
                lopéramide
              </a>
              .
            </p>
            <h3>
              <img
                alt
                src="/assets/files/pages/toepassingsgebied.jpeg"
                style={{
                  width: "26px",
                  height: "26px"
                }}
              />
              &nbsp;Domaine d'utilisation de IMODIUM<sup>®</sup> Instant&nbsp;:
            </h3>
            <p>
              Traitement symptomatique de la diarrhée aiguë et chronique
              d'origines diverses. Tout spécialement peut être recommandé pour
              la <strong>diarrhée du voyageur</strong> («&nbsp;turista&nbsp;»).
              Dans cette indication, un traitement de 2 jours doit suffire.
            </p>
            <p>&nbsp;</p>
            <h4>
              Posologie de&nbsp;IMODIUM<sup>®</sup> Instant
            </h4>
            <ul>
              <li>
                <strong>Posologie pour adultes :&nbsp;</strong>La dose initiale
                est de 2 comprimés orodispersibles, suivie, si besoin est, d’1
                comprimé orodispersible après chaque selle non moulée. Ne jamais
                dépasser 8 comprimés orodispersibles dans une période de 24
                heures.
                <br />
                &nbsp;
              </li>
              <li>
                <strong>
                  Posologie pour enfants à partir de 6 ans :&nbsp;
                </strong>
                La dose initiale est de 1 comprimé orodispersible, suivie, si
                besoin est, d’1 comprimé orodispersible après chaque selle non
                moulée. Dose journalière maximale&nbsp;: 3 comprimés
                orodispersibles par 20 kg de poids. Ne jamais dépasser 8
                comprimés orodispersibles dans une période de 24 heures.
                <br />
                &nbsp;
              </li>
              <li>
                <strong>Durée du traitement</strong> : ne pas utiliser plus de 2
                jours sans avis médical
              </li>
            </ul>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-fr.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="fr" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
